import React, { Component } from 'react';
import { NavLink, withRouter }  from "react-router-dom";
import { Helmet }               from "react-helmet";
import axios                    from 'axios';
import { animateScroll as scroll, scroller } from 'react-scroll';
import Cookies from "js-cookie";
import {localAnalyticsLogEvent, localAnalyticsLogPageView} from '../../../localAnalytics';
import Rollbar from 'rollbar';

import Menu       from 'components/partials/menu/Menu';
import Header     from 'components/partials/header/Header';
import DemoHeader from 'components/partials/showcase/Header';
import Heading    from 'components/modules/heading/Heading';
import Grid       from 'components/modules/grid/Grid';
import Dodont     from 'components/modules/do.dont/Dodont';
import Spacer     from 'components/modules/spacer/Spacer';
import Colors     from 'components/modules/colors/Colors';
import Carousel   from 'components/modules/carousel/Carousel';
import Downloads  from 'components/modules/downloads/Downloads';
import Typography from 'components/modules/typography/Typography';
import Content    from 'components/modules/content.grid/Content';
import Dash       from 'components/modules/dash/Dash';
import MobAlert   from 'components/partials/MobAlert';
import DashPortal from 'components/partials/integrations/dash/Dash';

import HelloFreshHeader from 'components/partials/organization/hellofresh/Header';
import CookieConsent from 'components/partials/cookie/CookieConsent';

import AudioPlayer  from 'components/partials/audioplayer/AudioPlayer';
import Attachments  from 'components/partials/modal/attachments/Attachments';

import Login        from 'components/partials/login/Login';
import Auth         from 'components/partials/auth/Auth';

import poweredLogo  from 'assets/img/corebook-logo-white.svg';
import StoneResized from 'assets/video/gem-resized.mp4';

import Error404     from 'components/base/error/Error';

import corebooklogowhite  from 'assets/img/corebook-logo-white.svg';
import laptoppng  from 'assets/img/photo-1525548002014-e18135d814d7.png';

import './Page.scss';
import '../error/Error.scss';

const api = axios.create();

const rollbarConfig = {
  accessToken: '5047cc4a81a8452db37994f95e99284e',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: 'production',
    client: {
      javascript: {
        code_version: '1.0.0',
        source_map_enabled: true
      }
    }
  }
};

const rollbar = new Rollbar(rollbarConfig);

class Page extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      brand: [],
      menu:  [],
      page: false,
      studio: '',
      modules: [],
      loadClass: '',
      pageTitle: '',
      menuopen: false,
      modal_item: [],
      modal_visible: false,
      nextPageLink: '/',
      nextPageLabel: false,
      nextPageBlank: false,
      audioStatus: 'ended',
      showAudioPlayer: false,
      audioProgress: 0,
      audio: {id:0},
      customFonts: [],
      customStyles: [],
      customBrand: false,
      customDomain: false,
      showCustomBrandWelcome: false,
      showError404: false,
      refs: [],
      isScrolledToRef: false,
      fadeDuration: 125,
      showFooter: 0,
      refId: 0,
      textCopied: false,
      scrollBehavior: 'auto',
      cookieConsentPopup: false,
      allowAllCookies: false,
      portal: false,
      dashPortalOpen: false,
      activeAnchor: 0,
      iframeUrl: '',
      damOpen: false,
      damInnerFilePopup: false,
      brandIdForEvents: false
    }
    this.toggleMenu         = this.toggleMenu.bind(this);
    this.openModal          = this.openModal.bind(this);
    this.playAudio          = this.playAudio.bind(this);
    this.pauseAudio         = this.pauseAudio.bind(this);
    this.changeAudioStatus  = this.changeAudioStatus.bind(this);
    this.hideAudioPlayer    = this.hideAudioPlayer.bind(this);
    this.changeProgress     = this.changeProgress.bind(this);
    this.closeAudio         = this.closeAudio.bind(this);
    this.logOut             = this.logOut.bind(this);
    this.editCookieSetttings = this.editCookieSetttings.bind(this);
    this.showCookieLink     = this.showCookieLink.bind(this);
    this.closeCookiePopup   = this.closeCookiePopup.bind(this);
    this.openDashPortal     = this.openDashPortal.bind(this);
    this.closeDashPortal    = this.closeDashPortal.bind(this);
    this.openDam            = this.openDam.bind(this);
    this.closeDam           = this.closeDam.bind(this);
    this.openDamFunc        = this.openDamFunc.bind(this);

    this.handleDamIframeMessages = this.handleDamIframeMessages.bind(this);
  }

  // Load brand and page data on page load
  componentDidMount() {
    let slug = '', props = this.props.match.params;
    let urlParams = new URLSearchParams(window.location.search);

    if (props.menuslug) {
      slug = props.menuslug;
    }

    if (props.pageslug) {
      slug = props.menuslug+'/'+props.pageslug;
    }

    if (props.subslug) {
      slug = props.menuslug+'/'+props.pageslug+'/'+props.subslug;
    }

    // Get data from props or Route params
    let brand = this.props.brand ? this.props.brand : this.props.match.params.brand;
    let studio = this.props.studio ? this.props.studio : this.props.match.params.studio;

    // User sign in token
    if (urlParams.get('token')) {
      localStorage.setItem('authToken', urlParams.get('token'));
      window.location.href = window.location.href.split('?')[0];
    }

    // Page Access Link token
    if (urlParams.get('access_token')) {
      localStorage.setItem('authToken', urlParams.get('access_token'));
    }

    this.setState({
      customBrand:  this.props.brand,
      customDomain: this.props.domain,
      studio:       this.props.studio
    });

    // API query
    api.get(window.API+brand+(slug || studio ? '?studio='+studio+'&page='+slug : ''), {
      headers: {
        Pragma: 'no-cache',
        Authorization: localStorage.getItem('authToken') || ''
      },
    })
      .then(result => {

        // Clear user session
        if (!result.data.access) {
          this.clearAuthSession();
        }

        // Empty brand in response, brand not found or custom domain not mapped yet
        if (!result.data.brand) {
          if (!this.state.customDomain) {
            this.props.history.push('/404')
          } else {
            this.setState({ showCustomBrandWelcome: true });
            this.setState({ loadClass: 'fade-in' });
            document.body.classList.add('body-loaded');
          }
        } else {

           // DAM

          var iframeUrl;
          if(window.EDITOR_URL.includes('http://localhost')) {
            iframeUrl = 'http://localhost:4200/dam/' + result.data.brand.hash + '/viewer?api_token=' + localStorage.getItem('authToken')
          } else {
            iframeUrl = window.EDITOR_URL + '/dam_app/dam/' + result.data.brand.hash + '/viewer?api_token=' + localStorage.getItem('authToken')
          }

          this.setState({
            iframeUrl:    iframeUrl,
            brandIdForEvents: result.data.brand.id
          });

          window.addEventListener('message', this.handleDamIframeMessages);

          if (result.data.brand === 'expired') {
            this.props.history.push('/expired')
          } else {

            let urlParams = new URLSearchParams(window.location.search);

            let moduleParam = urlParams.get('m') ? '?m='+urlParams.get('m') : '';
            let attachmentsParam = urlParams.get('p') ? '&p='+urlParams.get('p') : '';

            let self = this;

            if (slug && result.data.brand.visibility !== 'locked') {
              let newPage = [];

              if (result.data.redirect) {
                if (!this.state.customBrand || window.STUDIO) {
                  window.location.href = '/'+brand+'/'+result.data.redirect + moduleParam + attachmentsParam;
                } else {
                  window.location.href = '/'+result.data.redirect + moduleParam + attachmentsParam;
                }
                return false;
              }

              if (result.data.page && result.data.page.url === slug) {
                newPage = result.data.page;
              }

              if (newPage.length === 0) {
                this.setState({
                  showError404: true,
                  loadClass: 'fade-in'
                });

                document.body.classList.add('body-loaded');
                return false;
              }

              this.setState({
                page: newPage,
                modules: newPage.modules,
                refId: urlParams.get('m')*1,
                fadeDuration: 0
              });

              document.body.classList.add('menu--hidden'); // Hide menu on load

              this.showHideMenu();

              setTimeout(function() {
                self.setState({ loadClass: 'fade-in' });
                document.body.classList.add('body-loaded');

                if (!moduleParam) {
                  document.getElementById('app-content-scroll-wrapper').scrollTo(0, 1);
                }
              },500);

              // Remove refId not to trigger scroll on text selection
              setTimeout(function(){
                self.setState({ refId: 0 });
              },1500);
            } else {
              setTimeout(function(){
                self.setState({ loadClass: 'fade-in' });
                document.body.classList.add('body-loaded');
              },500);
            }

            this.setState({
              data:  result.data,
              brand: result.data.brand,
              menu:  result.data.menu,
              pageTitle: result.data.brand.meta_title ? result.data.brand.meta_title : (result.data.brand.title + (this.state.page.title ? ' - ' + this.state.page.title : '')),
              showFooter: result.data.brand.is_paid !== 1 && !result.data.brand.studio_id || result.data.brand.force_footer === 1 ? 1 : 0
            });

            this.calculateMenuAndContentWidth();
            window.addEventListener('resize', this.calculateMenuAndContentWidth.bind(this));

            if (result.data.brand.title === 'coreipsum') {
              localAnalyticsLogEvent("webflow_example_visit", {});
              localAnalyticsLogPageView();
            } else {
              localAnalyticsLogEvent("public_brand_visit", {
                brandId: result.data.brand.id,
                brandTitle: result.data.brand.title
              });
              localAnalyticsLogPageView();
            }
            localAnalyticsLogEvent("public_brand_pageview", {
              brandId: result.data.brand.id,
              brandPage: this.props.location.pathname
            });

            window.loadedBrand = result.data.brand.link;
            window.loadedBrandId = result.data.brand.id;
            window.loadedBrandHash = result.data.brand.hash;

            this.loadFonts();
            this.loadStyles();

            if (!slug && result.data.brand.visibility !== 'locked') {
              if (!result.data.pages) {
                this.setState({ showError404: true, loadClass: 'fade-in' });
                document.body.classList.add('body-loaded');
                return false;
              }

              // Show first visible page
              if (result.data.page.id) {
                this.setState({
                  loadClass: 'fade-in',
                  page: result.data.page,
                  modules: result.data.page.modules || [],
                  pageTitle: (this.state.brand.meta_title ? this.state.brand.meta_title : this.state.brand.title) + ' - ' + result.data.page.title
                });

                this.showHideMenu();
              }

              // No visible pages, redirect to first page
              if (!result.data.page.id) {
                let shouldSkip = false;
                result.data.pages.forEach(function(page) {
                  if (shouldSkip) {
                    return;
                  }

                  if (page.type === 'page') {
                    shouldSkip = true;

                    if (!self.state.customBrand || window.STUDIO) {
                      self.props.history.push('/'+brand+'/'+page.url)
                    } else {
                      self.props.history.push('/'+page.url)
                    }

                    return;
                  }
                })
              }
            }

            this.setNextPage();
          }
        }

        // Set user data
        this.setUserData(result.data.brand.user, result.data.access);

        this.manageCookieConsent();
        this.initURLs();
        this.formatMobile();
      })
      .catch(error => {
        let response = error.response;

        this.setState({
            ...this.state,
            showError404: true,
            showCustomBrandWelcome: response && response.data && response.data.brand ? false : this.state.customDomain,
            loadClass: 'fade-in'
        });
        document.body.classList.add('body-loaded');
       });

    document.addEventListener("mouseup", this.onMouseUp);
    document.addEventListener("mousemove", this.onMouseMove);
    document.addEventListener("click", this.onMouseClick);
    window.addEventListener('scroll', this.listenToScroll)
    window.addEventListener('message', this.listenDamEvents);
    //window.addEventListener('beforeunload', this.logoutAccessLink)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll)
    window.removeEventListener('message', this.listenDamEvents)
    window.removeEventListener('message', this.handleDamIframeMessages)
  }

  handleOpenLibraryClick = (hash) => {
    var iframe = document.getElementById('dam-iframe');
    var damOpenTimeout = setTimeout(() => {
      iframe.contentWindow.postMessage({ type: 'showModeFromPublicOpenHash', hash: hash, show: true }, '*');
      this.openDamFunc();
      clearTimeout(damOpenTimeout);
    }, 500)
  };


  handleDamIframeMessages(event) {

    //dam loaded in public open dam and send it to specific path - file for now
    if (event.data.message == 'damLoadedInPublic') {
      var damLoadTimeout = setTimeout(() => {
        let urlParams = new URLSearchParams(window.location.search);
        if(urlParams.get('dam')) {
          const str = urlParams.get('dam');
          const parts = str.split('/');
          const hash = parts[2];
          var iframe = document.getElementById('dam-iframe');
          var damOpenTimeout = setTimeout(() => {
            iframe.contentWindow.postMessage({ type: 'showModeFromPublicOpenHash', hash: hash, show: true }, '*');
            this.openDamFunc();
            clearTimeout(damOpenTimeout);
          }, 500)
          clearTimeout(damLoadTimeout);
        }
      }, 500)
    }

    if (event.data.message == 'damFileDownload') {
      localAnalyticsLogEvent("public_brand_external_public_dam_file_download", {
        url: this.state.iframeUrl,
        brandId: this.state.brandIdForEvents,
        items: event.data.items,
        appView: event.data.appView
      });
    }

    if (event.data.message == 'damFileDownloadMultiple') {
      localAnalyticsLogEvent("public_brand_external_public_dam_file_download_multiple", {
        url: this.state.iframeUrl,
        brandId: this.state.brandId,
        items: event.data.items,
        appView: event.data.appView
      });
    }

    if (event.data.message == 'damFileDownloadFromModal') {
      localAnalyticsLogEvent("public_brand_external_public_dam_file_download_from_single_modal", {
        url: this.state.iframeUrl,
        brandId: this.state.brandId,
        items: event.data.items,
        appView: event.data.appView
      });
    }

    if (event.data.message == 'navigateToLinkedGuidelinesInPublic') {
      const brand = this.state.brand.link;
      api.post(`${window.API}${brand}/page/module/get_public_link`,
        {
          id: event.data.file.linked_module, // Include the id parameter in the request body
        },
        {
          headers: {
            Pragma: 'no-cache',
            Authorization: localStorage.getItem('authToken') || '',
          },
        }
      )
      .then(result => {
        const link = !this.customDomain ? this.state.brand.link : '';
        const resultLink = result.data.link;

        const newUrl = `/${link}${resultLink}?m=${event.data.file.linked_module}`;
        this.props.history.push(newUrl); // Navigate to the new URL

        this.closeDam();
      });
    }
  }

  listenDamEvents = (event) => {
    this.setState(prevState => {
      const newDamInnerFilepopupState = event.data.value;

      // Return the new state
      return { damInnerFilePopup: newDamInnerFilepopupState };
    });
  }

  listenToScroll = () => {
    const winScroll = (document.body.scrollTop || document.documentElement.scrollTop || document.getElementById('app-content-scroll-wrapper').scrollTop) + 10;

    const collection = document.getElementsByClassName('anchor');
    const myElements = Array.from(collection);

    this.setState({ refId: 0 }); // Clear refId for scroll bug

    myElements.forEach(el => {
      if ((window.innerHeight + winScroll) >= this.divElement.clientHeight) {
        let el = myElements[myElements.length-1];
        let anchorClass = el.classList[0];
        anchorClass = anchorClass.split('_scroll_');
        if (this.state.activeAnchor != anchorClass[1]) {
          this.setState({ activeAnchor: anchorClass[1] });
        }
      } else {
        if (el.offsetTop <= winScroll && el.offsetTop + el.clientHeight > winScroll) {
          let anchorClass = el.classList[0];
          anchorClass = anchorClass.split('_scroll_');
          if (this.state.activeAnchor != anchorClass[1]) {
            this.setState({ activeAnchor: anchorClass[1] });
          }
        }
      }
    });
  }

  // Page is changed, new URL props received
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.match.params.menuslug) {
      let slug = newProps.match.params.menuslug;

      if (newProps.match.params.pageslug) {
        slug = newProps.match.params.menuslug+'/'+newProps.match.params.pageslug;
      }

      if (newProps.match.params.subslug) {
        slug = newProps.match.params.menuslug+'/'+newProps.match.params.pageslug+'/'+newProps.match.params.subslug;
      }

      this.setState({ loadClass: '', modal_visible: false, modal_item: [], refId: 0 });

      let urlParams = new URLSearchParams(newProps.location.search);
      let moduleParam = urlParams.get('m') ? '?m='+urlParams.get('m') : '';

      // Remove page fade on anchor links
      if (urlParams.get('m')) {
        this.setState({ loadClass: 'fade-in' });
      }

      setTimeout(() => {
        this.closeMenu();

        if (!moduleParam) {
          document.getElementById('app-content-scroll-wrapper').scrollTo(0, 0);
        }

        api.get(window.API+this.state.brand.link+'?studio='+this.state.studio+'&page='+slug+'&skip_menu=1', {
          headers: {
            Pragma: 'no-cache',
            Authorization: localStorage.getItem('authToken') || ''
          },
        })
          .then(result => {
            let newPage = result.data.page;

            // Clear user session
            if (!result.data.access) {
              this.clearAuthSession();
            }

            // Redirect to new URL
            if (result.data.redirect) {
              if (!this.state.customBrand) {
                window.location.href = '/' + this.state.brand + '/' + result.data.redirect + moduleParam;
              } else {
                window.location.href = '/' + result.data.redirect + moduleParam;
              }
              return false;
            }

            this.setState({
              page: newPage,
              modules: newPage.modules || [],
              pageTitle: (this.state.brand.meta_title ? this.state.brand.meta_title : this.state.brand.title) + ' - ' + newPage.title
            });

            if (this.state.brand.link === 'eOpjnLpz1u3G6IX8Met0HNTXffuFvZtZ' ||
              this.state.brand.link === 'MMM5ZYGjbmIVAWbiCfCPBvSWSvKvwqvU' ||
              this.state.brand.id === 21846 ||
              this.state.brand.id === 22959) {
              window.dataLayer.push({
                event: 'pageview',
                page: {
                  url: this.props.location.pathname,
                  title: newPage.title
                }
              });
            }

            localAnalyticsLogEvent("public_brand_pageview", {
              brandId: this.state.brand.id,
              brandPage: this.props.location.pathname
            });

            if (moduleParam) {
              this.setState({
                refId: urlParams.get('m')*1
              });
            }

            // Set user data
            this.setUserData(result.data.brand.user, result.data.access);

            this.showHideMenu();
            this.setNextPage();
            this.setState({loadClass: 'fade-in'});
            this.initURLs();
            this.formatMobile();
          });
      }, 300)
    } else {
      for (var p = 0; p < this.state.data.pages.length; p++) {
        if (this.state.data.pages[p].type === 'page') {
          if (!this.state.customBrand || window.STUDIO) {
            this.props.history.push('/'+this.props.match.params.brand+'/'+this.state.data.pages[p].url);
          } else {
            this.props.history.push('/'+this.state.data.pages[p].url);
          }
          break;
        }
      }
    }
  }

  // Turn HEX colour to HSL
  hexToHSL = function(H) {
    // function to keep search box always visible
    if (H !== undefined) {
      // Convert hex to RGB first
      let r = 0, g = 0, b = 0;
      if (H.length === 4) {
        r = "0x" + H[1] + H[1];
        g = "0x" + H[2] + H[2];
        b = "0x" + H[3] + H[3];
      } else if (H.length === 7) {
        r = "0x" + H[1] + H[2];
        g = "0x" + H[3] + H[4];
        b = "0x" + H[5] + H[6];
      }
      // Then to HSL
      r /= 255;
      g /= 255;
      b /= 255;
      let cmin = Math.min(r,g,b),
          cmax = Math.max(r,g,b),
          delta = cmax - cmin,
          h = 0,
          //s = 0,
          l = 0;

      if (delta === 0)
        h = 0;
      else if (cmax === r)
        h = ((g - b) / delta) % 6;
      else if (cmax === g)
        h = (b - r) / delta + 2;
      else
        h = (r - g) / delta + 4;

      h = Math.round(h * 60);

      if (h < 0)
        h += 360;

      l = (cmax + cmin) / 2;
      //s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
      //s = +(s * 100).toFixed(1);
      l = +(l * 100).toFixed(1);
      if (l < 20) {
        return "rgba(255,255,255, "+(10-l/4)+"% )";
      } else {
        return "rgba(0,0,0, "+(17-l/10)+"% )";
      }
    }
  }

  // Show cookie settings links for EU visitors
  showCookieLink() {
    return window.REGION === 'Europe' && window.location.pathname !== '/docs/policies/cookie-policy' && this.state.brand.consent === 1;
  }

  // Show or hide cookie consent popup
  manageCookieConsent() {

    if (!Cookies.get('CookiesAccepted') && this.showCookieLink()) {
      this.setState({
        cookieConsentPopup: true
      });
    }

    if (!Cookies.get('CookiesAccepted') && !this.showCookieLink()) {
      this.setState({
        allowAllCookies: true
      });
    }
  }

  // Check for cookie settings
  allowAllCookies = () => {
    return Cookies.get("CookiesAccepted") === '1' || !this.showCookieLink();
  };

  // On leave, logout from access link
  logoutAccessLink = () => {
    if (localStorage.getItem('authToken') && !localStorage.getItem('authUser')) {
      localStorage.removeItem('authToken');
    }
  }

  clearAuthSession = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('authUser');
  }

  logOut = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('authUser');
    window.location.href = window.location.href.split('?')[0];
  }

  userLoggedIn = () => {
    return localStorage.getItem('authUser');
  }

  accessLinkLoggedIn = () => {
    return localStorage.getItem('authToken') && !localStorage.getItem('authUser');
  }

  showHideMenu = () => {
    if (this.state.page.full_screen === 1) {
      document.body.classList.add('menu--hidden');
      if (this.state.page.hide_menu === 0) {
        document.body.classList.add('menu--burger');
      }
    } else {
      document.body.classList.remove('menu--hidden');
      document.body.classList.remove('menu--burger');
    }
  }

  playAudio = (audio) => {
    this.setState({
      refId: 0,
      showAudioPlayer: true
    });

    if (audio.id !== this.state.audio.id) {
      this.setState({
        audio: {id:0},
        audioStatus: '', audioProgress: 0
      });

      setTimeout(() => this.setState({
        audioStatus: 'play',
        audio: audio
      }),100);
    } else {
      this.setState({
        audioStatus: this.state.audioStatus === 'paused' ? 'resume' : 'play'
      });
    }
  }

  pauseAudio = (audio) => {
    this.setState({
      audioStatus: 'paused'
    });
  }

  changeAudioStatus = (status) => {
    if (status === 'play') {
      if (this.state.audioStatus !== 'paused') {
        this.setState({
          audioProgress: 0
        });
      }
      this.setState({
        audioStatus: this.state.audioStatus === 'paused' ? 'resume' : 'play'
      });
    } else {
      this.setState({
        audioStatus: status
      });
    }
  }

  closeAudio = () => {
    this.setState({
      audioStatus: 'paused',
      showAudioPlayer: false
    });
  }

  hideAudioPlayer = () => {
    this.setState({
      showAudioPlayer: false
    });
  }

  changeProgress = (progress) => {
    this.setState({
      audioProgress: progress
    });
  }

  toggleMenu = () => {
    this.setState({ menuopen: !this.state.menuopen });
    if (window.innerWidth <= 992) {
      if (this.state.menuopen) {
        document.body.classList.remove('no--overflow');
      } else {
        document.body.classList.add('no--overflow');
      }
    }
  }

  closeMenu = () => {
    this.setState({ menuopen: false });
    document.body.classList.remove('no--overflow');
  }

  openModal = (data,gallery,index) => {
    document.body.classList.add('attachmentModalOpened');
    this.setState({ modal_visible: true, modal_item: data, modal_gallery: gallery, modal_index: index ? index : 0 });
  }

  closeModal = (data) => {
    document.body.classList.remove('attachmentModalOpened');
    this.setState({ modal_visible: false, modal_item: [] });
  }

  // Show hidden menu when close to left screen side
  onMouseMove = (e) => {
    if (e.clientX < 15 && this.state.page.full_screen === 1 && document.body.classList.contains('menu--burger') && !this.state.menuopen) {
      this.toggleMenu();
    }
  }

  // Close opened menu on click outside menu
  onMouseClick = (e) => {
    if (this.state.menuopen && !e.target.closest('.brand-menu') && !e.target.closest('.burger-tap')) {
      this.toggleMenu();
    }

    //check if color item module data is copied
    var self = this;
    if (e.target.closest('.color-box') || e.target.closest('.copy-link')) {
      self.setState({ textCopied: true });

      setTimeout(() => {
         self.setState({ textCopied: false });
      },1000);

      document.querySelectorAll("span.click-to-copy").forEach(node => {
        node.classList.add('hide-copy-bubble');
      });
    }
  }

  // Check if string contains only spaces
  onlySpaces = (str) => {
    return /^\s*$/.test(str);
  }

  // Copy selected text to clipboard
  onMouseUp = (e) => {
    // TODO disable in search popup
    if (!window.searchOpened) {
      let self = this;
      let text = window.getSelection().toString();

      if (text && !this.onlySpaces(text) && this.state.page) {
        if (this.state.page.modules.length === 0) return true; // No content

        setTimeout(() => { // Timeout to allow text cards module catch selection
          if (window.getSelection) {
            if (window.getSelection().empty) {
              window.getSelection().empty();
            } else if (window.getSelection().removeAllRanges) {
              window.getSelection().removeAllRanges();
            }
          } else if (document.selection) {
            document.selection.empty();
          }

          const el = document.createElement('textarea');
          el.value = text;
          el.setAttribute('readonly', '');
          el.style.position = 'absolute';
          el.style.left = '-9999px';
          document.body.appendChild(el);
          el.select();

          let successCopy = document.execCommand('copy');
          document.body.removeChild(el);

          if (successCopy) {
            self.setState({ textCopied: true });

            setTimeout(() => {
               self.setState({ textCopied: false });
            },1000);
          }
        },10);
      }
    }
  }

  // Set user data from page load request
  setUserData(user, access) {
    if (user) {
      localStorage.setItem('authUser', JSON.stringify({
        email: user.email,
        type: access,
        image: user.image
      }));
    }
  }

  // Smooth crosslinks inside brand book
  initURLs() {
    if (this.state.brand.domain) {
      document.querySelectorAll("a[href^='https://"+this.state.brand.domain+"']").forEach(node => {
        node.addEventListener('click', e => {
          let clickedUrl = e.target.href ? e.target.href : e.target.parentElement.href;
          if (clickedUrl) {
            e.preventDefault();
            clickedUrl = clickedUrl.replace('https://'+this.state.brand.domain,'');
            this.props.history.push(clickedUrl);
          }
        });
      })
    }

    // Scroll to links in current page
    let thisLocation = window.location.href.split('?m=')[0];

    document.querySelectorAll("a").forEach(node => {
      // Scroll by m parameter in URL
      let checkRef = node.href.split('?m=');

      if (checkRef.length > 1 && checkRef[0] == thisLocation) {
        node.addEventListener('click', e => {
          e.preventDefault();

          scroller.scrollTo("_scroll_"+checkRef[1], {
            duration: 800,
            delay: 0,
            smooth: "easeInOut",
            containerId: 'app-content-scroll-wrapper'
          });
        });
      }

      // Scroll by internal anchor:
      let anchorRef = node.href.split('anchor:');

      if (anchorRef.length > 1) {
        node.addEventListener('click', e => {
          e.preventDefault();

          this.toggleMenu();

          scroller.scrollTo("_scroll_"+anchorRef[1], {
            duration: 800,
            delay: 0,
            smooth: "easeInOut",
            containerId: 'app-content-scroll-wrapper'
          });
        });
      }

      // Listen for internal page: URL
      let pageRef = node.href.split('page:');
      let pageUrl = false;

      if (pageRef.length > 1) {
        node.addEventListener('click', e => {
          for (var m = 0; m < this.state.menu.length; m++) {
            if (this.state.menu[m].page_id == pageRef[1]) {
              pageUrl = this.state.menu[m].url;
            }

            for (var p = 0; p < this.state.menu[m].pages.length; p++) {
              if (this.state.menu[m].pages[p].page_id == pageRef[1]) {
                pageUrl = this.state.menu[m].pages[p].url;
              }

              for (var c = 0; c < this.state.menu[m].pages[p].pages.length; c++) {
                if (this.state.menu[m].pages[p].pages[c].page_id == pageRef[1]) {
                  pageUrl = this.state.menu[m].pages[p].pages[c].url;
                }
              }
            }
          }

          if (pageUrl) {
            if (!this.state.customBrand || window.STUDIO) {
              this.props.history.push('/'+this.state.brand.link+'/'+pageUrl);
            } else {
              this.props.history.push('/'+pageUrl);
            }
          }

          e.preventDefault();
        });
      }
    })
  }

  // Format content for mobile
  formatMobile() {
    if (window.innerWidth <= 768) {
      document.querySelectorAll(".brand-content h1, .brand-content h2, .brand-content p").forEach(node => {
        if (node.style.lineHeight && node.style.lineHeight < 1) {
          node.style.lineHeight = 1;
        }
      })

      document.querySelectorAll(".brand-content span, .brand-content strong").forEach(node => {
        if (node.style.fontSize) {
          let fontSize = parseInt(node.style.fontSize, 10);
          let mobileSize = fontSize*0.62 > 48 ? 48 : (fontSize*0.62 < 14 ? 14 : fontSize*0.62);
          node.style.fontSize = mobileSize+'px';
        }
      })
    }
  }

  // Next page at footer
  setNextPage() {
    this.setState({
      nextPageLink: '/',
      nextPageLabel: false,
      nextPageBlank: false,
    });

    let pages = [];

    // Get all pages
    for (var m = 0; m < this.state.menu.length; m++) {
      if (this.state.menu[m].type === 'page') {
        pages.push(this.state.menu[m]);
      }

      for (var p = 0; p < this.state.menu[m].pages.length; p++) {
        if (this.state.menu[m].pages[p].type === 'page') {
          this.state.menu[m].pages[p].parent = this.state.menu[m].title;
          pages.push(this.state.menu[m].pages[p]);
        }

        for (var c = 0; c < this.state.menu[m].pages[p].pages.length; c++) {
          if (this.state.menu[m].pages[p].pages[c].type === 'page') {
            this.state.menu[m].pages[p].pages[c].parent = this.state.menu[m].title + ' / ' + this.state.menu[m].pages[p].title;
            pages.push(this.state.menu[m].pages[p].pages[c]);
          }
        }
      }
    }

    // Loop trough pages to set next page data
    for (var i = 0; i < pages.length; i++) {
      if (pages[i].id === this.state.page.id && pages[i+1]) {

        // Search next unprotected page
        for(var search = i+1; search < pages.length; search++) {
          if (pages[search].protected === 0) {
            let nextPage = pages[search];
            if (!this.state.customBrand || window.STUDIO) {
              this.setState({
                nextPageLink: '/' + this.state.brand.link + '/' + nextPage.url,
                nextPageLabel: nextPage.parent_id > 0 ? nextPage.parent+' / '+nextPage.title : nextPage.title,
                nextPageBlank: nextPage.page_id === 889807586489778 || nextPage.page_id === 713127054289203,
              });
            } else {
              this.setState({
                nextPageLink: '/' + nextPage.url,
                nextPageLabel: nextPage.parent_id > 0 ? nextPage.parent+' / '+nextPage.title : nextPage.title,
                nextPageBlank: false,
              });
            }
            break;
          }
        }
      }
    }
  }

  // Brand fonts
  loadFonts() {
    if (!this.state.brand.fonts) return false;

    for(var index = 0; index < this.state.brand.fonts.length; index++) {
      let font = this.state.brand.fonts[index];

      if (font.type === 'upload') {
        let customFonts = this.state.customFonts;

        customFonts.push(font);

        this.setState({
          customFonts: customFonts
        });

        window.WebFont.load({
          custom: {
            families: [''+font.name+'']
          }
        });
      }

      if (font.type === 'google') {
          window.WebFont.load({
            google: {
              families: [''+font.name+':100,100i,300,300i,400,400i,500,500i,700,700i,900,900i']
            }
          });
      }
    }

    // Typekit font
    if (this.state.brand.typekit) {
      window.WebFont.load({
        custom: {
          urls: [''+this.state.brand.typekit_css+'']
        }
      });
    }
  }

  // Brand custom styles
  loadStyles() {
    if (!this.state.brand.styles) return false;

    for(var i = 0; i < this.state.brand.styles.length; i++) {
      let style = this.state.brand.styles[i];
      let customStyles = this.state.customStyles;

      customStyles.push(style);

      this.setState({
        customStyles: customStyles
      });
    }
  }

  handlePoweredByClick(e) {
    localAnalyticsLogEvent("public_powered_by_corebook_click", {
      brandId: this.state.brand.id
    });
  }

  // Open cookie settings
  editCookieSetttings() {
    this.setState({
      cookieConsentPopup: true
    });
  }

  // Open cookie settings
  closeCookiePopup() {
    this.setState({
      cookieConsentPopup: false
    });
  }

  // Open Dash Portal
  openDashPortal = (portal) => {
    this.setState({
      portal: portal,
      dashPortalOpen: true
    });
  }

  // Close Dash Portal
  closeDashPortal = () => {
    this.setState({
      dashPortalOpen: false
    });
  }

  calculateMenuAndContentWidth = function() {
    if (window.innerWidth-parseInt(this.state.brand.menu_width) >= 1045) {
      this.setState({wideMenu: false});
    } else {
      this.setState({wideMenu: true});
    }
  }

  // Open DAM
  openDam() {

    var iframe = document.getElementById('dam-iframe');

    // Use the callback form of setState to ensure we get the latest state
    this.setState(prevState => {
      const newDamOpenState = !prevState.damOpen;

      // send studio data
      var studioDataToSend = {};
      if(this.state.brand.studio) {
        studioDataToSend.studio = this.state.brand.studio;
      }

      // Get the current URL
      var currentUrl = window.location.href;
      var urlToDam;

      currentUrl = currentUrl.replace(/[?&]dam=[^&]*/g, '');

      // Check if the URL already contains '?'
      if (currentUrl.includes('?')) {
          // Append '&dam=true' if '?' is already present
          urlToDam = currentUrl + '&dam=';
      } else {
          // Append '?dam=true' if '?' is not present
          urlToDam = currentUrl + '?dam=';
      }

      iframe.contentWindow.postMessage({ type: 'showModeFromPublic', internalCopyUrlPublic: urlToDam, show: newDamOpenState, studioData: studioDataToSend }, '*');

      // Return the new state
      return { damOpen: newDamOpenState };
    });
  }

  // Close DAM
  closeDam() {
    this.setState(prevState => {
      const newDamOpenState = false;
      return { damOpen: newDamOpenState };
    });
  }

  openDamFunc() {
    this.setState(prevState => {
      const newDamOpenState = true;
      return { damOpen: newDamOpenState };
    });
  }

  // Render page
  render () {
    let brand = this.state.brand;
    return (
      <div className={`${this.state.wideMenu ? 'big-menu' : ''} ${this.state.damOpen ? 'dam-opened-main-wrapper' : ''}`}>
        {!this.state.showCustomBrandWelcome &&
        <Helmet>
          <title>{this.state.pageTitle}</title>
          <meta name="og:title" content={this.state.pageTitle} />
          <meta name="og:type" content="website" />
          <body data-brand={brand.link_alias}  />
          {this.state.customFonts.map(font => {
          return <style key={font.name} type="text/css">{`
            @font-face {
              font-family: `+font.name+`;
              src:url("`+font.filename+`") format("`+font.format+`");
            }
          `}</style>
          })}

          <style type="text/css">{`
          .module-grid .title-quill, .carousel-div .title-quill {
              font-size: `+brand.body_font_size/1.4+`px !important;
          }
          .burger-tap.open {
            left: calc(`+brand.menu_width+`px + 15px) ;
          }
          .brand-menu {
            background-color: `+brand.menu_bg_color+`;
            background-image: `+(brand.sidebar_background ? 'url('+brand.sidebar_background+')' : 'none')+`;
            background-size: cover;
            background-position: `+brand.sidebar_background_x+`% `+brand.sidebar_background_y+`%;
            background-repeat: no-repeat;
          }
          .brand-menu,
          .brand-menu h3 {
            font-family: `+brand.menu_font+`;
            font-weight: `+brand.menu_font_weight+` !important;
            font-size: `+brand.menu_font_size+`px !important;
            line-height: `+brand.menu_line_height+` !important;
            letter-spacing: `+(brand.menu_letter_spacing === '0' || brand.menu_letter_spacing === '0.0' ? 'normal' : brand.menu_letter_spacing + 'px') + ` !important;
          }
          .brand-content {
            background-color: `+brand.main_background_color+`;
          }
          .brand-content,
          .brand-content input {
            font-family: `+brand.body_font+`;
            font-weight: `+brand.body_font_weight+` !important;
            font-size: `+brand.body_font_size+`px !important;
            letter-spacing: `+(brand.body_letter_spacing === '0' || brand.body_letter_spacing === '0.0' ? 'normal' : brand.body_letter_spacing + 'px') + ` !important;
          }
          @media (max-width: 768px) {
            .brand-content,
            .brand-content input {
              font-size: `+(brand.body_font_size*0.62 > 28 ? 28 : (brand.body_font_size*0.62 < 14 ? 14 : brand.body_font_size*0.62))+`px !important;
            }
          }
          .brand-content, .brand-content p, .brand-content ul li, .brand-content ol li {
            line-height: `+brand.body_line_height+`;
          }
          .brand-content .login p {
            line-height: 1.4 !important;
          }
          .brand-content h1,
          .ql-blank-h1::before,
          .brand-content .login h1,
          .brand-content .login h2,
          .brand-content .login h6 {
            font-family: `+brand.h1_font+`;
            font-weight: `+brand.h1_font_weight+` !important;
            font-size: `+brand.h1_font_size+`px !important;
            line-height: `+brand.h1_line_height+`;
            letter-spacing: `+(brand.h1_letter_spacing === '0' || brand.h1_letter_spacing === '0.0' ? 'normal' : brand.h1_letter_spacing + 'px') + ` !important;
          }
          @media (max-width: 768px) {
            .brand-content h1,
            .brand-content .login h1,
            .brand-content .login h2,
            .brand-content .login h6,
            .ql-blank-h1::before {
              font-size: `+(brand.h1_font_size*0.62 > 48 ? 48 : brand.h1_font_size*0.62)+`px !important;
            }
          }
          .brand-content h2,
          .ql-blank-h2::before {
            font-family: `+brand.h2_font+`;
            font-weight: `+brand.h2_font_weight+` !important;
            font-size: `+brand.h2_font_size+`px !important;
            line-height: `+brand.h2_line_height+`;
            letter-spacing: `+(brand.h2_letter_spacing === '0' || brand.h2_letter_spacing === '0.0' ? 'normal' : brand.h2_letter_spacing + 'px') + ` !important;
          }
          @media (max-width: 768px) {
            .brand-content h2,
            .ql-blank-h2::before {
              font-size: `+(brand.h2_font_size*0.62 > 38 ? 38 : brand.h2_font_size*0.62)+`px !important;
            }
          }
          .editor-content h1 {
            color: `+brand.h1_color+` !important;
          }
          .editor-content h2 {
            color: `+brand.h2_color+` !important;
          }
          .editor-content p,
          .editor-content ol li,
          .editor-content ol li:before,
          .module_audio .title {
            color: `+brand.text_color+` !important;
          }
          .module-colors__item .title {
            color: `+brand.text_color+`;
          }
          .editor-content ul li {
            color: `+brand.text_color+`;
          }
          .next-page__content a h6 {
            font-family: `+brand.body_font+` !important;
            font-weight: `+brand.body_font_weight+` !important;
          }
          .powered-by {
            color: `+brand.text_color+`;
          }
          .module_audio__play .ico-audio-play span,
          .ko-progress-circle .ko-progress-circle__slice .ko-progress-circle__fill,
          .audio-player__buttons,
          .audio-player__volume #volume-track span,
          .audio-player__progress span#seek-obj-container span,
          .audio-player progress::-webkit-progress-value,
          .audio-player progress::-webkit-progress-value  {
            background-color: `+brand.play_audio_color+` !important;
          }
          .audio-player progress[value],
          .audio-player progress[value]::-webkit-progress-bar {
            color: `+brand.play_audio_color+` !important;
          }
          .ico-prev-next-text-cards {
            fill: `+brand.text_cards_icon_color+`;
          }
          .card-tabs ul li:after {
            background-color: `+brand.text_cards_color+` !important;
          }
          @media(max-width:992px) {
            .brand-header {
              background-color: `+brand.menu_bg_color+`;
            }
          }
          .burger-tap {
            background: `+brand.menu_bg_color+`;
          }
          .burger-tap .burger span {
             background-color: `+brand.active_item_color+`;
          }
          .btn-download-float,
          .module_audio__download {
             background-color: `+brand.attachment_icon_background_color+`;
          }
          .btn-download-float use,
          .module_audio__download use {
             stroke: `+brand.attachment_icon_color+`;
          }
          .modal-image ul li:hover,
          .module_downloads .module_downloads__item:hover {
            background-color: `+brand.attachment_icon_background_color+` !important;
          }
          .modal-image ul li:hover a,
          .module_downloads .module_downloads__item:hover h6 {
            color: `+brand.attachment_icon_color+` !important;
          }
          .modal-image ul li:hover use,
          .module_downloads .module_downloads__item:hover use {
            stroke: `+brand.attachment_icon_color+`;
          }
          .card-tabs ul li:before {
            background: `+brand.text_cards_color+`1F;
          }
          .btn-switch-cards,
          .btn-switch-slide,
          .mobile-scroll-hint {
            background-color: `+brand.text_cards_color+`;
          }
          .btn-switch-cards svg path,
          .btn-switch-slide svg path,
          .mobile-scroll-hint svg path {
            fill: `+brand.text_cards_icon_color+`;
          }
          .text-copied,
          .copy-link:hover:before {
            color: `+brand.highlights_text_color+`;
            background-color: `+brand.highlights_color+`;
          }
          .module-colors .module-colors__item .data span.click-to-copy:before {
            color: `+brand.highlights_text_color+` !important;
            background-color: `+brand.highlights_color+` !important;
          }
          .module-colors .module-colors__item .icon-block-copy-over svg .st0 {
            fill: `+brand.highlights_text_color+` !important;
          }
          .text-copied svg use {
            stroke: `+brand.highlights_text_color+`;
          }
          .copy-link:hover svg path {
            fill: `+brand.highlights_text_color+` !important;
          }
          svg.icon-copied use {
            stroke: `+brand.highlights_text_color+` !important;
          }
          ::selection {
            color: `+brand.highlights_text_color+` !important;
            background: `+brand.highlights_color+` !important;
          }
          span.click-to-copy:hover {
            color: `+brand.highlights_text_color+` !important;
            background: `+brand.highlights_color+` !important;
          }
          .fixed-click-to-copy:before {
            color: `+brand.highlights_text_color+` !important;
            background: `+brand.highlights_color+` !important;
          }
          .fixed-click-to-copy svg path {
            fill: `+brand.highlights_text_color+` !important;
          }
          .modal-image .close:hover {
            background: `+brand.highlights_color+` !important;
          }
          .file-info .file-info__title {
            color: `+brand.highlights_text_color+`;
            background: `+brand.highlights_color+`;
          }
          .video .sound-block .mute, .video .sound-block .unmute {
            background-color: `+brand.highlights_color+`;
          }
          .video .sound-block .mute svg path, .video .sound-block .unmute svg path {
            fill: `+brand.highlights_text_color+`;
          }
          .search-core .search-popup-frame-header-title {
            color: `+brand.highlights_text_color+`;
          }
          .search-core .search-popup-frame-header {
            background: `+brand.highlights_color+`;
          }
          .next-page__content a span,
          .next-page__content a h6 {
            color: `+brand.footer_text_color+`;
          }
          .next-page__content a svg use {
            stroke: `+brand.footer_text_color+`;
          }
          .powered-by {
            color: `+brand.footer_text_color+`;
          }
          .cookies-setting-caller-div:hover, .by--cb a:hover {
            background: rgba(255, 255, 255, 0.1);
          }
          .cookies-setting-caller-div {
            font-size: 14px;
          }
          .brand-menu .scroll-inner::-webkit-scrollbar-thumb {
            background-color: `+this.hexToHSL(brand.menu_bg_color)+` !important;
          }
          .brand-menu .brand-data,
          .brand-menu .brand-block .brand-block__section,
          .brand-menu .brand-block .brand-block__section a,
          .brand-menu .brand-block ul li,
          .brand-menu .brand-block ul li a,
          .brand-menu .brand-block__sub .brand-block__sub__item a {
              color: `+brand.menu_item_color+` !important;
          }
          .brand-menu .brand-block__section.active a,
          .brand-menu .brand-block__section.active span,
          .brand-menu .brand-block__sub__item.active a,
          .brand-menu .brand-block ul li.active,
          .brand-menu .brand-block a.active,
          .brand-menu .brand-block span.active {
              font-weight: bold;
              color: `+brand.active_item_color+` !important;
          }
          .brand-menu .brand-block__section {
              padding-left: `+(24 + (brand.menu_font_size / 2))+`px !important;
          }
          .ico-menu-arrow,
          .ico-page-locked {
              width: `+(brand.menu_font_size / 2)+`px !important;
              height: `+(brand.menu_font_size / 2)+`px !important;
          }
          .ico-menu-arrow path {
              fill: `+brand.menu_item_color+`;
          }
          .brand-menu .brand-block .brand-block__section.active .ico-menu-arrow path {
              fill: `+brand.active_item_color+`;
          }
          .ico-page-locked {
              margin-top: -1px !important;
              width: `+((brand.menu_font_size / 2) + 4)+`px !important;
              height: `+((brand.menu_font_size / 2) + 4)+`px !important;
          }
          .ico-page-locked path,
          .ico-page-locked rect {
              stroke: `+brand.menu_item_color+`;
          }
          .brand-block__section.active .ico-page-locked path,
          .brand-block__section.active .ico-page-locked rect,
          .brand-block__sub__item.active .ico-page-locked path,
          .brand-block__sub__item.active .ico-page-locked rect {
              stroke: `+brand.active_item_color+`;
          }
          .multibrand-switcher ul li:hover .multibrand-switcher__logo {
            outline-color: `+brand.menu_item_color+`;
          }
          .multibrand-switcher ul li.active .multibrand-switcher__logo,
          .multibrand-switcher ul li.active:hover .multibrand-switcher__logo {
            outline-color: `+brand.active_item_color+`;
          }
          .multibrand-switcher__title {
            color: `+brand.highlights_text_color+`;
            background-color: `+brand.highlights_color+`;
          }
          `}</style>

          {((this.state.brand.menu_hover === 'yes')) &&
            <style type="text/css">{`
            .brand-menu .brand-block .brand-block__section a:hover,
            .brand-menu .brand-block .brand-block__sub a:hover,
            .brand-menu .brand-block ul li a:hover,
            .brand-menu .brand-block__sub__item:hover a,
            .brand-block .brand-block__section:hover,
            .brand-block .brand-block__section:hover a {
                color: `+brand.menu_hover_color+` !important;
                font-weight: `+brand.menu_font_weight+` !important;
            }
            .brand-menu .brand-block .brand-block__section a.active:hover,
            .brand-menu .brand-block .brand-block__sub a.active:hover
            .brand-menu .brand-block .brand-block__section:hover .active,
            .brand-menu .brand-block__sub .brand-block__sub__item:hover a.active,
            .brand-block .brand-block__section.active:hover,
            .brand-block .brand-block__section.active:hover a,
            .brand-block .brand-block__section:hover .active {
                font-weight: bold !important;
            }
            .brand-menu .brand-block__sub .brand-block__sub__item a.active:hover {
                color: `+brand.active_item_color+` !important;
            }
            .brand-block .brand-block__section:hover .ico-menu-arrow path {
                fill: `+brand.menu_hover_color+` !important;
            }
            .brand-block__sub__item:hover .ico-page-locked path,
            .brand-block__sub__item:hover .ico-page-locked rect {
                stroke: `+brand.menu_hover_color+` !important;
            }
            .brand-block .brand-block__section:hover .ico-page-locked path,
            .brand-block .brand-block__section:hover .ico-page-locked rect {
                stroke: `+brand.menu_hover_color+` !important;
            }
            .brand-block .brand-block__section.active:hover .ico-menu-arrow path {
                fill: `+brand.active_item_color+` !important;
            }
            .brand-block .brand-block__sub__item.active:hover .ico-page-locked path,
            .brand-block .brand-block__sub__item.active:hover .ico-page-locked rect,
            .brand-block .brand-block__section.active:hover .ico-page-locked path,
            .brand-block .brand-block__section.active:hover .ico-page-locked rect {
                stroke: `+brand.active_item_color+` !important;
            }
            `}</style>
          }

          {((this.state.brand.footer_visible === 1)) &&
            <style type="text/css">{`
            .cookies-setting-caller-div:hover {
              background: `+this.hexToHSL(brand.footer_background)+`;
            }
            `}</style>
          }

          {((this.state.page.protected === 1) || (brand.visibility === 'locked') || (this.state.menu.length === 0)) &&
          <style type="text/css">{`
          .brand-content {
            background-color: #ffffff !important;
          }
          `}</style>
          }

          {((brand.menu_font_size > 30)) &&
          <style type="text/css">{`
          .trigger-search-popup {
            font-size: 30px !important;
          }
          .search-trigger-icon-text-box-deggree-symbol {
              font-size: 34px !important;
          }
          `}</style>
          }

          {((brand.menu_width > 350)) &&
          <style type="text/css">{`
          .copy-link {
            left: 2%;
          }
          `}</style>
          }

          {this.state.customStyles.map(style => {
          return <style key={style.id} type="text/css">{`
            .cb_custom-style_`+style.id+` {
              color: `+style.color+`;
              font-family: `+style.font+`;
              font-weight: `+style.font_weight+` !important;
              font-size: `+style.font_size+`px !important;
              line-height: `+style.line_height+` !important;
              letter-spacing: `+(style.letter_spacing === '0' || style.letter_spacing === '0.0' ? 'normal' : style.letter_spacing+'px')+` !important;
            }
            .editor-content a.cb_custom-style_`+style.id+` {
              color: `+style.color+`;
            }
            @media (max-width: 768px) {
              .cb_custom-style_`+style.id+` {
                line-height: `+(style.line_height < 1 ? 1 : style.line_height)+` !important;
                font-size: `+(style.font_size*0.62 > 48 ? 48 : (style.font_size*0.62 < 14 ? 14 : style.font_size*0.62))+`px !important;
              }
            }
          `}</style>
          })}
        </Helmet>
        }

        {!this.state.showCustomBrandWelcome && this.state.menu.length > 0 &&
        <Header
          toggleMenu={this.toggleMenu}
          menuopen={this.state.menuopen}
          brandVisibility={brand.visibility}
          brand={brand}
          customBrand={this.state.customBrand}
          customDomain={this.state.customDomain}
        />
        }

        {brand.showcase &&
        <DemoHeader />
        }

        {brand.organization && brand.organization.header &&
        <HelloFreshHeader brand={this.state.brand} />
        }

        {!this.state.showCustomBrandWelcome && brand.visibility !== 'locked' && this.state.menu.length > 0 &&
        <Menu
          brand={brand}
          menu={this.state.menu}
          page={this.state.page}
          menuopen={this.state.menuopen}
          showAudioPlayer={this.state.showAudioPlayer}
          customBrand={this.state.customBrand}
          customDomain={this.state.customDomain}
          activeAnchor={this.state.activeAnchor}
        />
        }

        {!this.state.showCustomBrandWelcome && !this.state.showError404 &&
          <div ref={(divElement) => { this.divElement = divElement }} className={'container container-full full-height brand-'+this.state.brand.link+' ' + (this.state.brand.showcase ? ' showcase' : '') + (this.state.showAudioPlayer ? ' audio-playing' : '') + (this.showCookieLink() ? ' cookie-link-showing' : '') + (this.state.brand.footer_visible === 1 ? ' footer-showing' : ' footer-not-showing')}>
            <div className="row full-height">
              <div style={{
                  width: this.state.brand.menu_width === '240' ? '' : 'calc(100% - ' +this.state.brand.menu_width + 'px)',
                  marginLeft: this.state.brand.menu_width === '240' ? '' : this.state.brand.menu_width+ 'px'
                }}
                className={'col-md-12 brand-content ' + this.state.loadClass + (this.state.nextPageLabel && this.state.page.protected !== 1 && this.state.brand.footer_visible === 1 ? ' with-next-page' : '') + ' powered--' +this.state.showFooter + (brand.visibility === 'locked' || this.state.menu.length === 0 ? ' full-width' : '')}>
                {brand.show_login === '1' && (
                  <Auth
                    brand={brand}
                    page={this.state.page}
                    showLogin={this.state.page.protected !== 1 && brand.visibility !== 'locked' && this.state.menu.length > 0}
                    openDam={this.openDam}
                    onCloseDam={this.closeDam}
                    onOpenDamFunc={this.openDamFunc}
                    damOpen={this.state.damOpen}
                  />
                )}

                {brand.show_login !== '1' && this.userLoggedIn() && (
                  <Auth
                    brand={brand}
                    page={this.state.page}
                    showLogin={true}
                    openDam={this.openDam}
                    onCloseDam={this.closeDam}
                    onOpenDamFunc={this.openDamFunc}
                    damOpen={this.state.damOpen}
                  />
                )}

                {brand.show_login !== '1' && this.accessLinkLoggedIn() && (
                  <Auth
                    brand={brand}
                    page={this.state.page}
                    showLogin={true}
                    openDam={this.openDam}
                    onCloseDam={this.closeDam}
                    onOpenDamFunc={this.openDamFunc}
                    damOpen={this.state.damOpen}
                  />
                )}

                {brand.organization && brand.organization.id === 'Nny0zt7NBAJnRvMrYHvfB6pKHkFMAcHDzsmGJLfT' &&
                  <MobAlert brand="HelloFresh Group" link={this.state.brand.link} />
                }

                {brand.link === 'redkap' &&
                  <MobAlert brand={brand.title} link={this.state.brand.link} />
                }

                {brand.link === 'lovehoney' &&
                  <MobAlert brand={brand.title} link={this.state.brand.link} />
                }

                {brand.dash_portal_id &&
                  <DashPortal brand={brand} portal={this.state.portal} dashPortalOpen={this.state.dashPortalOpen} closeDashPortal={this.closeDashPortal} />
                }

                <div className="page-content">
                  {((this.state.page.protected === 1) || (brand.visibility === 'locked') || (this.state.menu.length === 0 && this.state.modules.length === 0)) &&
                    <Login brand={brand} studio={this.state.studio} page={this.state.page} menu={this.state.menu} />
                  }

                  {this.state.modules.map((module,index) => {
                    if (module.module === 'carousel')
                    return <div className={'_scroll_'+module.id+(index === 0 ? ' anchor first-module' : ' anchor')} key={module.id} ref={(el) => { if (el != null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Carousel data={module} /></div>

                    if (module.module === 'colors')
                    return <div className={'_scroll_'+module.id+(index === 0 ? ' anchor first-module' : ' anchor')} key={module.id} ref={(el) => { if (el != null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Colors brand={this.state.brand} data={module} /></div>

                    if (module.module === 'downloads')
                    return <div className={'_scroll_'+module.id+(index === 0 ? ' anchor first-module' : ' anchor')} key={module.id} ref={(el) => { if (el != null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Downloads brandId={this.state.brand.id} brand={this.state.brand.link} data={module} showcase={brand.showcase} /></div>

                    if (module.module === 'grid')
                    return <div className={'_scroll_'+module.id+(index === 0 ? ' anchor first-module' : ' anchor')} key={module.id} ref={(el) => { if (el != null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Grid data={module} openModal={this.openModal} /></div>

                    if (module.module === 'do.dont')
                    return <div className={'_scroll_'+module.id+(index === 0 ? ' anchor first-module' : ' anchor')} key={module.id} ref={(el) => { if (el != null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Dodont data={module} openModal={this.openModal} /></div>

                    if (module.module === 'heading')
                    return <div className={'_scroll_'+module.id+(index === 0 ? ' anchor first-module' : ' anchor')} key={module.id} ref={(el) => { if (el != null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Heading data={module} /></div>

                    if (module.module === 'spacer')
                    return <div className={'_scroll_'+module.id+(index === 0 ? ' anchor first-module' : ' anchor')} key={module.id} ref={(el) => { if (el != null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Spacer data={module} /></div>

                    if (module.module === 'typography')
                    return <div className={'_scroll_'+module.id+(index === 0 ? ' anchor first-module' : ' anchor')} key={module.id} ref={(el) => { if (el != null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Typography data={module} /></div>

                    if (module.module === 'content.grid')
                    return <div className={'_scroll_'+module.id+(index === 0 ? ' anchor first-module' : ' anchor')} key={module.id} ref={(el) => { if (el != null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Content brand={this.state.brand} data={module} openModal={this.openModal} audio={this.state.audio} audioStatus={this.state.audioStatus} audioProgress={this.state.audioProgress} playAudio={this.playAudio} pauseAudio={this.pauseAudio} /></div>

                    if (module.module === 'dash')
                    return <div className={'_scroll_'+module.id+(index === 0 ? ' anchor first-module' : ' anchor')} key={module.id} ref={(el) => { if (el != null && module.id === this.state.refId) { el.scrollIntoView({ behavior: this.state.scrollBehavior }); } }}><Dash brand={this.state.brand.link} data={module} openDashPortal={this.openDashPortal} /></div>

                    return '';
                  })}
                </div>

                {this.state.nextPageLabel && this.state.page.protected !== 1 && this.state.brand.footer_visible === 1 &&
                <div className={'next-page'}
                  style={{
                    backgroundColor:this.state.brand.footer_background,
                    backgroundImage:this.state.brand.footer_bg_image ? 'url('+this.state.brand.footer_bg_image+')' : 'none',
                    backgroundPosition:this.state.brand.footer_bg_image_x+'% '+this.state.brand.footer_bg_image_y+'%'
                  }}>
                  {this.state.brand.footer_dim === 1 &&
                  <div className="next-page__bg-dim"></div>
                  }

                  {!this.state.nextPageBlank &&
                  <div className={'next-page__content padding--'+this.state.brand.footer_padding}>
                    <NavLink
                      to={this.state.nextPageLink}
                      style={{
                        paddingTop: (window.innerWidth <= 768 ? (this.state.brand.footer_padding_top / 2.4).toFixed(0) : this.state.brand.footer_padding_top)+'px',
                        paddingBottom: (window.innerWidth <= 768 ? (this.state.brand.footer_padding_bottom / 2.4).toFixed(0) : this.state.brand.footer_padding_bottom)+'px'
                      }}
                      >
                      <div className="footer-relative">
                        <span>NEXT</span>
                        <h6>
                          {this.state.nextPageLabel}
                        </h6>
                        <svg width="8" height="14" viewBox="0 0 8 14"><defs><path id="904oa" d="M1528 9795l6-6-6-6"/></defs><g><g transform="translate(-1527 -9782)"><use fill="#fff" fillOpacity="0" stroke="#303030" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#904oa"/></g></g></svg>
                      </div>
                    </NavLink>
                  </div>
                  }

                  {this.state.nextPageBlank &&
                  <div className={'next-page__content padding--'+this.state.brand.footer_padding}>
                    <a
                      href="https://tech.corebook.io?access_token=l4OaUqZDoTCEfHAuFp0zBdKtNmhvIVrksnX2JgGQc8M69jebRWyL3YSiP15x7w"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        paddingTop: this.state.brand.footer_padding_top+'px',
                        paddingBottom: this.state.brand.footer_padding_bottom+'px'
                      }}
                      >
                      <div className="footer-relative">
                        <span>NEXT</span>
                        <h6>
                          {this.state.nextPageLabel}
                        </h6>
                        <svg width="8" height="14" viewBox="0 0 8 14"><defs><path id="904oa" d="M1528 9795l6-6-6-6"/></defs><g><g transform="translate(-1527 -9782)"><use fill="#fff" fillOpacity="0" stroke="#303030" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" xlinkHref="#904oa"/></g></g></svg>
                      </div>
                    </a>
                  </div>
                  }
                </div>
                }

                {this.state.showFooter === 1 &&
                <div className={'powered-by by--cb ' + (this.state.menu.length === 0 ? ' full--width' : '')}>

                  <a onClick={this.handlePoweredByClick.bind(this)} href={'https://www.'+window.MAIN_DOMAIN} target="_blank" rel="noopener noreferrer">
                    Powered by
                    <img src={poweredLogo} alt="Corebook" />
                  </a>

                  {/* EU visitor */}
                  {this.showCookieLink() &&
                    <div style={{ display: 'inline' }}>
                      <span style={{ marginLeft: '10px' }}>|</span>
                      <div className="cookies-setting-caller-div" onClick={this.editCookieSetttings} style={{ cursor: 'pointer', display: 'inline', marginLeft: '10px' }}>{this.state.brand.cc_settings}</div>
                    </div>
                  }
                </div>
                }

                {/* EU visitor */}
                {this.state.showFooter !== 1 && this.showCookieLink() &&
                <div className={'powered-by' + (this.state.menu.length === 0 ? ' full--width' : '')}>
                  <div className="cookies-setting-caller-div" onClick={this.editCookieSetttings} style={{ cursor: 'pointer' }}>{this.state.brand.cc_settings}</div>
                </div>
                }

                <div className={'text-copied' + (this.state.textCopied ? ' visible' : '')}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15">
                    <defs><path id="b3cia" d="M1095 702l-11 11-5-5"/></defs>
                    <g><g transform="translate(-1077 -700)"><use fill="#fff" fillOpacity="0" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="50" strokeWidth="3" xlinkHref="#b3cia"/></g></g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        }

        {! this.state.showCustomBrandWelcome && this.state.showError404 &&
        <Error404  />
        }

        {this.state.showCustomBrandWelcome &&
        <div className="error_404">
          <div className="video">
            <video playsInline autoPlay loop muted>
              <source src={StoneResized} type="video/mp4" />
            </video>
          </div>

          <div className="error_404__content">
            <h1>Domain</h1>
            <p>Go to brand settings and map domain name</p>
          </div>
        </div>
        }

        <CookieConsent
          brand={this.state.brand}
          status={this.state.cookieConsentPopup}
          closeCookiePopup={this.closeCookiePopup}
        />

        {!this.state.showCustomBrandWelcome &&
        <Attachments
          closeModal={this.closeModal}
          show={this.state.modal_visible}
          item={this.state.modal_item}
          gallery={this.state.modal_gallery}
          index={this.state.modal_index}
          brand={this.state.brand.link}
          modules={this.state.modules}
          brandBackgroundColor={this.state.brand.main_background_color}
          onOpenLibraryClick={this.handleOpenLibraryClick}
        />
        }

        {!this.state.showCustomBrandWelcome &&
        <AudioPlayer
          audio={this.state.audio}
          audioStatus={this.state.audioStatus}
          showAudioPlayer={this.state.showAudioPlayer}
          audioProgress={this.state.audioProgress}
          changeAudioStatus={this.changeAudioStatus}
          hideAudioPlayer={this.hideAudioPlayer}
          changeProgress={this.changeProgress}
          closeAudio={this.closeAudio}
          openModal={this.openModal}
        />
        }

        {this.userLoggedIn() && this.state.brand.dam === 1 && (
            <iframe
                allowFullScreen
                className={`${this.state.damOpen ? 'dam-opened' : ''} ${this.state.damInnerFilePopup ? 'dam-inner-file-modal-opened' : ''}`}
                id="dam-iframe"
                src={this.state.iframeUrl}
                allow="clipboard-read; clipboard-write"
            ></iframe>
        )}
        <div className="mobile-alert ng-scope">
          <div className="mobile-alert__logo">
            <a href="https://corebook.io">
              <img src={corebooklogowhite} alt="Corebook logo" />
            </a>
          </div>

          <div className="mobile-alert__content">
            <img src={laptoppng} alt="Laptop" />
            <div className="mobile-alert__text-box">
              <h6>Assets library is <br />desktop exclusive</h6>

            </div>
          </div>

          <div className="mobile-alert__input">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 100 125"
              style={{ enableBackground: 'new 0 0 100 125' }}
              xmlSpace="preserve"
            >
              <style type="text/css">
                {`.st0{fill:#FFFFFF;}`}
              </style>
              <path
                className="st0"
                d="M91.5,23.6c0-3.3-2.7-5.9-5.9-5.9l0,0H14.5c-3.3,0-5.9,2.7-5.9,5.9l0,0v48.5h83L91.5,23.6L91.5,23.6z
                  M84.6,65.2H15.4V24.6h69.1C84.5,24.6,84.5,65.2,84.6,65.2z"
              ></path>
              <path
                className="st0"
                d="M58.7,74.1c0.2,0.3,0.2,0.7,0.2,1c0,1.2-1,2.2-2.2,2.2h0H43.3c-1.2,0-2.2-1-2.2-2.2l0,0c0-0.3,0.1-0.7,0.2-1H0
                  c0,4.5,4.1,8.2,9.1,8.2h81.9c5,0,9.1-3.7,9.1-8.2H58.7z"
              ></path>
            </svg>

            <div className="ng-binding">http://localhost:8000/quickstart</div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Page);
